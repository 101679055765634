import { StaticQuery, graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';
import DHSlick from '@shares/dh-slick';
import { Settings } from 'react-slick';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { appendParamsCurrentPage, PageSection } from '@utils';
import { trackPartner, getTrackingDealerName } from './tracking';
import { FunctionComponent } from 'react';
import { WEB_LEGACY_LINK } from '@config';

interface PartnersQueryResult {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: {
          path: string;
          brand_image_for_homepage: IGatsbyImageData;
          brand_image_for_homepage_alt: string;
          name_en: string;
          name_th: string;
          activate: boolean;
          inter_vendor_id: string;
          position: string;
        };
      };
    }[];
  };
}
interface PartnersProps {
  prefix: string;
  sectionName: PageSection;
  hideService?: boolean;
  brandID?: string;
}
const SLICK_SETTINGS: Settings = {
  dots: false,
  infinite: false,
  speed: 600,
  slidesToShow: 7,
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        className: 'slider variable-width slider--dh-mobile',
        arrows: false,
        speed: 800,
        slidesToShow: 1,
        variableWidth: true,
        useTransform: false,
        useCSS: false
      }
    }
  ]
};

export const Partners: FunctionComponent<PartnersProps> = ({
  prefix,
  hideService,
  brandID,
  sectionName
}: PartnersProps) => {
  const url = (node: any) => {
    const partnerPageURL = `${WEB_LEGACY_LINK}/${prefix}/${node.frontmatter.path}`;
    const url = appendParamsCurrentPage(partnerPageURL);
    return url;
  };

  return (
    <StaticQuery
      query={graphql`
        query listInterBrand {
          allMarkdownRemark(
            filter: { fields: { collection: { eq: "interbrands" } }, frontmatter: { activate: { eq: true } } }
          ) {
            edges {
              node {
                frontmatter {
                  path
                  brand_image_for_homepage {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP], width: 100)
                    }
                  }
                  brand_image_for_homepage_alt
                  name_en
                  name_th
                  activate
                  inter_vendor_id
                  position
                }
              }
            }
          }
        }
      `}
      render={(data: PartnersQueryResult) => {
        const interBrands = data.allMarkdownRemark.edges;
        return (
          <div className={`partners partners--modify  ${hideService ? '' : ''}`}>
            <Container>
              <h2 className="title">พันธมิตรของเรา</h2>
              <LazyLoad height={125}>
                <DHSlick
                  settings={SLICK_SETTINGS}
                  isLoading={false}
                  sectionName="partner"
                  arrowClassName="arrow-partner"
                >
                  {interBrands
                    .filter(({ node }) => {
                      if (node.frontmatter.inter_vendor_id === '3') return;
                      if (brandID) return node.frontmatter.inter_vendor_id !== brandID;
                      return node;
                    })
                    .sort((a, b) => parseInt(a.node.frontmatter.position) - parseInt(b.node.frontmatter.position))
                    .map(({ node }) => {
                      const imageData = getImage(node.frontmatter.brand_image_for_homepage);
                      return (
                        <Card
                          onClick={(e: any) => trackPartner(e, sectionName)}
                          data-event-label={`dealer_${getTrackingDealerName(node.frontmatter[`name_${prefix}`])}`}
                          key={`เช่ารถ ${node.frontmatter[`name_${prefix}`]}`}
                          className="card--partner bg-transparent"
                        >
                          <a href={url(node)}>
                            {imageData && (
                              <GatsbyImage
                                image={imageData}
                                alt={node.frontmatter.brand_image_for_homepage_alt}
                                className="partner-logo"
                              />
                            )}
                            <Card.Body>
                              <Card.Title className="text-center">
                                <h5 className="m-0">เช่ารถ {node.frontmatter[`name_${prefix}`]}</h5>
                              </Card.Title>
                            </Card.Body>
                          </a>
                        </Card>
                      );
                    })}
                </DHSlick>
              </LazyLoad>
            </Container>
          </div>
        );
      }}
    />
  );
};

export default Partners;
