import { FunctionComponent, Fragment } from 'react';
import Services from './services';
import Partners from './partners';
import { PageSection } from '@utils';
import './style.scss';

interface OurDrivehubProps {
  prefix: string;
  sectionName: PageSection;
  hideService?: boolean;
  brandID?: string;
}
export const OurDrivehub: FunctionComponent<OurDrivehubProps> = ({
  prefix,
  hideService,
  brandID,
  sectionName
}: OurDrivehubProps) => {
  return (
    <Fragment>
      {!hideService && <Services prefix={prefix} sectionName={sectionName} />}
      <Partners prefix={prefix} hideService={hideService} brandID={brandID} sectionName={sectionName} />
    </Fragment>
  );
};

export default OurDrivehub;
