import LazyLoad from 'react-lazyload';
import { searchPath, appendParamsCurrentPage, PageSection } from '@utils';
import { StaticImage } from 'gatsby-plugin-image';
import { WEB_LEGACY_LINK } from '@config';
import { trackDrivehubService, trackServiceRental } from './tracking';
import { FunctionComponent } from 'react';

interface ServicesProps {
  prefix: string;
  sectionName: PageSection;
}
export const Services: FunctionComponent<ServicesProps> = ({ prefix, sectionName }) => {
  return (
    <div className="services services--modify">
      <div className="container">
        <h2>บริการเช่ารถของ Drivehub</h2>
        <LazyLoad height={125}>
          <div className="wrapper">
            <div className="card-widget">
              <div className="card-container scrollable overlay card--services">
                <a
                  onClick={(e) =>
                    trackServiceRental(e, 'search_bookings_success', sectionName, {
                      rentalType: 'shortterm',
                      url: searchPath('rent_short')
                    })
                  }
                  data-event-label="rental_type_เช่ารถระยะสั้น"
                  href={searchPath('rent_short')}
                  className="card shadow"
                >
                  <div className="card-img">
                    <StaticImage
                      src={'./images/rent_short.webp'}
                      className="card-img-top"
                      alt="เช่ารถระยะสั้น"
                      placeholder="blurred"
                      formats={['auto', 'webp']}
                      style={{ height: 117 }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">เช่ารถระยะสั้น</h5>
                  </div>
                </a>

                <a
                  onClick={(e) => trackDrivehubService(e, sectionName)}
                  data-event-label="rental_type_เช่ารถระยะยาว"
                  href={appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${prefix}/long-term-rental`)}
                  className="card shadow"
                >
                  <div className="card-img">
                    <StaticImage
                      src="./images/rent_long.webp"
                      className="card-img-top"
                      alt="เช่ารถระยะยาว"
                      placeholder="blurred"
                      formats={['auto', 'webp']}
                      style={{ height: 117 }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">เช่ารถระยะยาว</h5>
                  </div>
                </a>

                <a
                  onClick={(e) => trackDrivehubService(e, sectionName)}
                  data-event-label="rental_type_เช่ารถพร้อมคนขับ"
                  href={appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${prefix}/car-with-driver`)}
                  className="card shadow"
                >
                  <div className="card-img">
                    <StaticImage
                      src="./images/rent_cwd.webp"
                      className="card-img-top"
                      alt="เช่ารถพร้อมคนขับ"
                      placeholder="blurred"
                      formats={['auto', 'webp']}
                      style={{ height: 117 }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">เช่ารถพร้อมคนขับ</h5>
                  </div>
                </a>

                <a
                  onClick={(e) =>
                    trackServiceRental(e, 'search_bookings_success', sectionName, {
                      dealerType: 'local',
                      url: searchPath('rent_local')
                    })
                  }
                  data-event-label="rental_type_เช่ารถไม่ใช้บัตรเครดิต"
                  data-event-category="homepage_section"
                  href={searchPath('rent_local')}
                  className="card shadow"
                >
                  <div className="card-img">
                    <span className="badge badge-local badge-sm">
                      <i className="icon-local"></i>LOCAL
                    </span>
                    <StaticImage
                      src="./images/rent_local.webp"
                      className="card-img-top"
                      alt="เช่ารถไม่ใช้บัตรเครดิต"
                      placeholder="blurred"
                      formats={['auto', 'webp']}
                      style={{ height: 117 }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">เช่ารถไม่ใช้บัตรเครดิต</h5>
                  </div>
                </a>

                <a
                  onClick={(e) =>
                    trackServiceRental(e, 'search_bookings_success', sectionName, {
                      dealerType: 'inter',
                      url: searchPath('rent_inter')
                    })
                  }
                  data-event-label="rental_type_เช่ารถบริษัทรายใหญ่"
                  data-event-category="homepage_section"
                  href={searchPath('rent_inter')}
                  className="card shadow"
                >
                  <div className="card-img">
                    <span className="badge badge-inter badge-sm">
                      <i className="icon-inter"></i>INTER
                    </span>
                    <StaticImage
                      src="./images/rent_inter.webp"
                      className="card-img-top"
                      alt="เช่ารถบริษัทรายใหญ่"
                      placeholder="blurred"
                      layout="fullWidth"
                      formats={['auto', 'webp']}
                      style={{ height: 117 }}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">เช่ารถบริษัทรายใหญ่</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </LazyLoad>
      </div>
    </div>
  );
};

export default Services;
