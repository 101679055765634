import { FunctionComponent } from 'react';
import { CustomArrowProps } from 'react-slick';
import styled, { css } from 'styled-components';

type ArrowDiraction = 'next' | 'previous';

interface DhArrowProp extends CustomArrowProps {
  type: string;
  slidesToShow: number | undefined;
  sectionName: Section;
  onClickTracking?: (e: React.MouseEvent<any, MouseEvent>) => void;
  arrowClassName?: string;
}

const handleOnClick = (
  e: React.MouseEvent<any, MouseEvent>,
  sectionName: Section,
  onClick: any,
  type: string,
  currentSlide: number | undefined,
  maxSlice: number
) => {
  const direction: ArrowDiraction = type === 'next' ? 'next' : 'previous';
  if (currentSlide === undefined) return;
  if (direction === 'previous' && currentSlide === 0) return;
  if (direction === 'next' && maxSlice - currentSlide < 0) return;
  if (direction === 'next' && currentSlide === maxSlice) return;
  onClick();
};

export const DhArrow: FunctionComponent<DhArrowProp> = (props: DhArrowProp): React.ReactElement => {
  const { type, onClick, slideCount, currentSlide, slidesToShow, sectionName, arrowClassName, onClickTracking } = props;
  if (slideCount === undefined) return <div />;
  if (slidesToShow === undefined) return <div />;

  const maxSlice = slideCount - slidesToShow;

  return (
    <Arrow
      onClick={(e) => {
        handleOnClick(e, sectionName, onClick, type, currentSlide, maxSlice);
        if (onClickTracking) onClickTracking(e);
      }}
      id={`slick-arrow-${type}`}
      type={type}
      currentSlide={currentSlide}
      maxSlice={maxSlice}
      className={arrowClassName}
    >
      <i className={`${type === 'next' ? 'icon-right' : 'icon-left'}`} />
    </Arrow>
  );
};

interface ArrowProps {
  type: string;
  currentSlide: number | undefined;
  maxSlice: number;
  className?: string;
}

const Arrow = styled.div`
  position: absolute;
  ${(props: ArrowProps) =>
    props.className === 'arrow-partner'
      ? css`
          top: 25%;
        `
      : css`
          top: 43.5%;
        `}
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  border: solid 1px #eeeeee;
  border-radius: 21px;
  z-index: 10;
  :hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  }
  i:before {
    color: #0078ff;
    font-size: 16px;
    ${(props: ArrowProps) =>
      props.type === 'next'
        ? css`
            padding-left: 3px;
          `
        : css`
            padding-right: 3px;
          `}
  }
  ${(props: ArrowProps) =>
    props.type === 'next'
      ? css`
          right: calc(0% - 9vh);
          @media screen and (max-width: 1350px) {
            right: calc(0% - 5vh);
          }
          ${props.currentSlide && (props.currentSlide === props.maxSlice || props.maxSlice - props.currentSlide < 0)
            ? css`
                i:before {
                  color: #eef0f2;
                }
                :hover {
                  box-shadow: unset;
                }
              `
            : ''}
        `
      : css`
          left: calc(0% - 9vh);
          @media screen and (max-width: 1350px) {
            left: calc(0% - 5vh);
          }
          ${props.currentSlide === 0
            ? css`
                i:before {
                  color: #eef0f2;
                }
                :hover {
                  box-shadow: unset;
                }
              `
            : ''}
        `}
`;
interface DhPagingProps {
  i: number;
  handleTracking?: (index: number) => void;
  onClick?: any;
}
export const DhPaging: FunctionComponent<DhPagingProps> = ({ i, onClick, handleTracking }) => {
  return (
    <Paging
      onClick={(e) => {
        onClick(e);
        handleTracking && handleTracking(i);
      }}
    />
  );
};

const Paging = styled.div`
  background-color: #9b9b9b;
  width: 10px;
  height: 10px;
  opacity: 0.8;
  border-radius: 6px;
  transition: background-color 0.3s;
  transition-timing-function: ease;
`;

export const DhDots: FunctionComponent<any> = (props: any) => {
  return (
    <Dots className={`${props.className} ${props.modifyClassName ? props.modifyClassName : ''}`}>{props.children}</Dots>
  );
};

const Dots = styled.div`
  ul {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -30px !important;
    margin: 0px !important;
    list-style-type: none;
    padding: 0;
  }
  li {
    width: unset !important;
    height: unset !important;
    margin: 0px 8px !important;
  }
  .slick-active {
    div {
      background-color: #0078ff;
      opacity: 0.9;
    }
  }
`;
