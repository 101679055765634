import { PureComponent } from 'react';
import Slider, { Settings } from 'react-slick';
import { DhDots, DhArrow, DhPaging } from './custom-slick';
import './style.scss';

class DhSlick extends PureComponent<{
  settings: Settings;
  children: React.ReactNode;
  isLoading: boolean;
  sectionName: Section;
  arrowClassName?: string;
  onClickArrow?: (e: React.MouseEvent<any, MouseEvent>, direction: string) => void;
}> {
  slickDefaultSettings = () => {
    const { settings, sectionName, arrowClassName, onClickArrow } = this.props;
    const { appendDots } = settings;
    const newSettings: Settings = {
      nextArrow: (
        <DhArrow
          type={'next'}
          slidesToShow={settings.slidesToShow}
          sectionName={sectionName}
          arrowClassName={arrowClassName}
          onClickTracking={(e) => onClickArrow && onClickArrow(e, 'next')}
        />
      ),
      prevArrow: (
        <DhArrow
          type={'prev'}
          slidesToShow={settings.slidesToShow}
          sectionName={sectionName}
          arrowClassName={arrowClassName}
          onClickTracking={(e) => onClickArrow && onClickArrow(e, 'prev')}
        />
      ),
      customPaging: (i) => <DhPaging i={i} />,
      ...settings
    };
    if (appendDots) {
      return newSettings;
    } else {
      newSettings['appendDots'] = (dots) => (
        <DhDots>
          <ul>{dots}</ul>
        </DhDots>
      );
    }

    return newSettings;
  };
  render() {
    const { children, isLoading } = this.props;

    return (
      <>
        {isLoading ? (
          <div style={{ minHeight: 350, display: 'flex', alignItems: 'center' }}>
            {/* <LoadingWheel height={40} width={40} /> */}
            Loading
          </div>
        ) : (
          <Slider {...this.slickDefaultSettings()}>{children}</Slider>
        )}
      </>
    );
  }
}

export default DhSlick;
