import querystring from 'querystring';
import {
  sendToDataLayer,
  getTrackingTimestamp,
  convertToDateAndTime,
  getDefaultEventCategory,
  PageSection
} from '@utils';

export const trackDrivehubService = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sectionName: PageSection) => {
  sendToDataLayer(e, { event_category: getDefaultEventCategory(sectionName) });
};

export const trackServiceRental = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  customAction: string,
  sectionName: PageSection,
  serviceDetail?: {
    rentalType?: string;
    dealerType?: string;
    url?: string;
  }
) => {
  const searchDetail = {
    search_pickup_date: 'undefined',
    search_pickup_time: 'undefined',
    search_return_date: 'undefined',
    search_return_time: 'undefined',
    search_destination: 'เชียงใหม่',
    search_pickup_location: 'สนามบินเชียงใหม่'
  };

  if (serviceDetail?.url) {
    const parse = querystring.parse(serviceDetail?.url);
    const bookingBegin = convertToDateAndTime(parse.booking_begin as string);
    const bookingEnd = convertToDateAndTime(parse.booking_end as string);
    searchDetail.search_pickup_date = bookingBegin.date;
    searchDetail.search_pickup_time = bookingBegin.time;
    searchDetail.search_return_date = bookingEnd.date;
    searchDetail.search_return_time = bookingEnd.time;
  }

  const customeDimension = {
    search_dealer_type: `${serviceDetail?.dealerType || 'undefined'}`,
    search_dealer_name: 'undefined',
    search_car_type: 'undefined',
    search_promotion_name: 'undefined',
    search_rental_type: `${serviceDetail?.rentalType || 'undefined'}`,
    sort_by: 'undefined',
    search_time_stamp: getTrackingTimestamp(),
    ...searchDetail
  };
  sendToDataLayer(e, {
    event_category: getDefaultEventCategory(sectionName),
    event_action: customAction,
    ...customeDimension
  });
};

export const trackPartner = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, sectionName: PageSection) => {
  sendToDataLayer(e, { event_category: getDefaultEventCategory(sectionName) });
};
export const getTrackingDealerName = (dealerName: string) => {
  return dealerName.toLowerCase().replace(/ /g, '_');
};
