import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { FunctionComponent } from 'react';

const BackgroundSection: FunctionComponent<{
  src: IGatsbyImageData;
  children: React.ReactNode;
  id?: string;
}> = ({ src, children, id }) => {
  const imageData = src;
  const rawImage = getImage(imageData);
  const bgImage = convertToBgImage(rawImage as IGatsbyImageData);
  return (
    <section style={{ position: 'relative', zIndex: 1 }}>
      <BackgroundImage Tag="section" {...bgImage} id={id} preserveStackingContext>
        {children}
      </BackgroundImage>
    </section>
  );
};

export default BackgroundSection;
